import { Col, Form, Input, message, Row } from "antd";
import React, { useEffect, useState } from 'react';
import { cadastroSelector, fetchCadastro, fetchLogs, fetchPermissoes } from "../../../../store/consultivoConsulta";
import { useTranslation } from "react-i18next";
import useDispatchWithResult from "../../../../hooks/useDispatchWithResult";
import { useSelector } from "react-redux";
import useLoading from "../../../../hooks/useLoading";
import { useHistory, useRouteMatch } from "react-router";
import unwrapAxiosPromise from "../../../../util/unwrapAxiosPromise";
import axios from "axios";
import ActionBar from "../../../../components/action-bar";
import ActionButton from "../../../../components/action-button";
import MiniForm from "../../../../components/mini-form";
import SelectConsultivoEmpresa from "../../../../components/select-consultivo-empresa";
import SelectConsultivoUnidade from "../../../../components/select-consultivo-unidade";
import SelectConsultivoArea from "../../../../components/select-consultivo-area";
import SelectConsultivoConsultaPrioridade from "../../../../components/select-consultivo-consulta-prioridade";
import SelectConsultivoTipoTrabalho from "../../../../components/select-consultivo-tipo-trabalho";
import SelectConsultivoAssunto from "../../../../components/select-consultivo-assunto";
import SelectConsultivoUsuario from "../../../../components/select-consultivo-usuario";
import InputEmails from "../../../../components/input-emails";
import SelectConsultivoConsultaStatus from "../../../../components/select-consultivo-consulta-status";
import SelectBoolean from "../../../../components/select-boolean";
import InputNumber from "../../../../components/input-number";
import InputDate from "../../../../components/input-date";

export default function ConsultivoConsultaCadastroEditPage() {
  const { t } = useTranslation();
  const dispatch = useDispatchWithResult();
  const [form] = Form.useForm();
  const cadastro = useSelector(cadastroSelector);
  const loading = useLoading(fetchCadastro.typePrefix);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const consultaId = match.params.consultaId;
  const acessoId = cadastro?.empresa?.acesso?.id;

  useEffect(() => {
    if (cadastro?.id)
      document.querySelector('#empresaId')?.focus();
  }, [cadastro]);

  useEffect(() => {
    form.setFieldsValue(cadastro);
  }, [cadastro]);

  const handleFinish = values => {
    setLoadingSave(true);
    unwrapAxiosPromise(axios.put(`/consultivo/consultas/${consultaId}/cadastro`, values), form)
      .then(() => {
        message.success(t('Registro alterado com sucesso'));
        history.replace(`/consultivo/consultas/${consultaId}/cadastro`);
        dispatch(fetchPermissoes({ consultaId }));
        dispatch(fetchCadastro({ consultaId }));
        dispatch(fetchLogs({ consultaId }));
      })
      .finally(() => setLoadingSave(false));
  };

  const handleCancel = () => {
    history.replace(`/consultivo/consultas/${consultaId}/cadastro`);
  };

  return (
    <MiniForm form={form} onFinish={handleFinish} loading={loading}>
      <ActionBar>
        <ActionButton.Save onClick={form.submit} loading={loadingSave}/>
        <ActionButton.Cancel onClick={handleCancel} disabled={loadingSave}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item name="empresaId" label={t('Empresa')}>
            <SelectConsultivoEmpresa disabled={loading} acessoId={acessoId}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="status" label={t('Status')}>
            <SelectConsultivoConsultaStatus disabled/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.empresaId !== nextValues.empresaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="unidadeId" label={t('Unidade')}>
                <SelectConsultivoUnidade
                  allowClear
                  disabled={loading}
                  acessoId={acessoId}
                  empresaId={getFieldValue('empresaId')}
                />
              </Form.Item>
            }
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="dataAbertura" label={t('Abertura em')}>
            <InputDate disabled/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item name="areaId" label={t('Área de Atuação')}>
            <SelectConsultivoArea disabled={loading}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="idExterno" label={t('Id Externo')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="tipoTrabalhoId" label={t('Tipo de Trabalho')}>
                <SelectConsultivoTipoTrabalho areaId={getFieldValue('areaId')} disabled={loading}/>
              </Form.Item>
            }
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="prioridade" label={t('Prioridade')}>
            <SelectConsultivoConsultaPrioridade disabled={loading}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="tempoEstimado" label={t('Tempo Estimado')}>
            <InputNumber disabled maxLength={5} min={0} max={99999} maximumFractionDigits={0} suffix={t('dia(s)')}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="dataEstimada" label={t('Data Estimada para conclusão')}>
            <InputDate disabled/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="assuntoId" label={t('Assunto')}>
                <SelectConsultivoAssunto areaId={getFieldValue('areaId')} disabled={loading}/>
              </Form.Item>
            }
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="dataInicio" label={t('Início em')}>
            <InputDate disabled/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="dataConclusao" label={t('Concluído em')}>
            <InputDate disabled/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="dataPrevista" label={t('Data Prevista para conclusão')}>
            <InputDate/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px">
          <Form.Item name="titulo" label={t('Título')}>
            <Input disabled={loading}/>
          </Form.Item>
        </Col>
        <Col flex="200px">
          <Form.Item name="sigilo" label={t('Sigilo')}>
            <SelectBoolean disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="descricao" label={t('Descrição')}>
            <Input.TextArea autoSize disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item name="usuarioSolicitanteId" label={t('Solicitante')}>
            <SelectConsultivoUsuario disabled={loading} params={{ tipo: 'solicitante', acessoId }}/>
          </Form.Item>
        </Col>
        <Col flex="400px">
          <Form.Item name="usuarioCriadorId" label={t('Criador')}>
            <SelectConsultivoUsuario disabled/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="400px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="usuarioControleId" label={t('Controle')}>
                <SelectConsultivoUsuario disabled={loading} params={{ tipo: 'controle', areaId: getFieldValue('areaId') }}/>
              </Form.Item>
            }
          </Form.Item>
        </Col>
        <Col flex="400px">
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.areaId !== nextValues.areaId}
          >
            {({ getFieldValue }) =>
              <Form.Item name="usuarioResponsavelId" label={t('Responsável')}>
                <SelectConsultivoUsuario disabled={loading} params={{ tipo: 'responsavel', areaId: getFieldValue('areaId') }}/>
              </Form.Item>
            }
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px">
          <Form.Item name="emailsExtras" label={t('E-mails Extras')}>
            <InputEmails disabled={loading}/>
          </Form.Item>
        </Col>
      </Row>
    </MiniForm>
  )
}
