import { Col, Row, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import ActionBar from '../../../../../components/action-bar';
import ActionButton from '../../../../../components/action-button';
import TextAnexo from '../../../../../components/text-anexo';
import TextItem from '../../../../../components/text-item';
import useFormatter from '../../../../../hooks/useFormatter';
import useLoading from '../../../../../hooks/useLoading';
import { contratoSelector, fetchContrato, isEditableSelector } from '../../../../../store/empresa';
import { openPopup } from '../../../../../util/window';
import useModulo from "../../../../../hooks/useModulo";
import { findByIdAndMap } from "../../../../../constants";

export default function EmpresaContratoDetailPage2() {
  const { t } = useTranslation();
  const isEditable = useSelector(isEditableSelector);
  const contrato = useSelector(contratoSelector);
  const { formatDateTime, formatDate, formatBoolean } = useFormatter();
  const loading = useLoading(fetchContrato.typePrefix);
  const history = useHistory();
  const match = useRouteMatch();
  const empresaId = match.params.empresaId;
  const id = match.params.id;
  const { moduloBasePath } = useModulo();

  const handleEdit = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/contratos/${id}/edit`);
  };

  const handleBack = () => {
    history.replace(`${moduloBasePath}/empresas/${empresaId}/contratos`);
  };

  const handleLogs = () => {
    openPopup(`/logs?categoria=/empresas/${empresaId}/contratos/${id}/`, `logs_/empresas/${empresaId}/contratos/${id}/`);
  };

  return (
    <Spin spinning={loading}>
      <ActionBar>
        {isEditable && <ActionButton.Edit onClick={handleEdit}/>}
        <ActionButton.Back onClick={handleBack}/>
        <ActionButton.Logs noTitle onClick={handleLogs}/>
      </ActionBar>
      <Row gutter={[16, 8]}>
        <Col flex="400px"><TextItem label={t('Nome do Ato')}>{contrato?.nomeAto}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Data do Ato')}>{formatDate(contrato?.dataAto)}</TextItem></Col>
        <Col flex="200px"><TextItem label={t('Data de Registro')}>{formatDate(contrato?.dataRegistro)}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextAnexo label={t('Anexo')} value={contrato?.anexo}/></Col>
        <Col flex="200px"><TextItem label={t('Órgão')}>{findByIdAndMap(Orgao, contrato?.orgao, orgao => t(orgao.label))}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="600px"><TextItem label={t('Livro Digital')}>{contrato?.livroDigital?.natureza}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="800px"><TextItem label={t('Observação')}>{contrato?.obs}</TextItem></Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col flex="200px"><TextItem label="Criado em">{formatDateTime(contrato?.createdAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Alterado em">{formatDateTime(contrato?.updatedAt)}</TextItem></Col>
        <Col flex="200px"><TextItem label="Id">{contrato?.id}</TextItem></Col>
      </Row>
    </Spin>
  );
}
