import React, { useEffect } from 'react';
import AcessoMenu from "../../components/acesso-menu";
import AuthenticatedLayout from "../../layouts/authenticated";
import { useSelector } from "react-redux";
import { acessoSelector } from "../../store/acessos";
import { isClienteGestorSelector } from "../../store/autenticacao";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import { useHistory } from "react-router";
import WorkflowMenu from "../../components/workflow-menu";

export default function WorkflowsPage() {
  const history = useHistory();
  const acesso = useSelector(acessoSelector)
  const isClienteGestor = useSelector(isClienteGestorSelector);

  const acessoId = acesso?.id

  useEffect(() => {
    if (acessoId > 0) {
      unwrapAxiosPromise(axios.get('/workflows', { params: { acessoId } }))
        .then(data => {
          if (data.length === 0)
            return unwrapAxiosPromise(axios.post('/workflows', { nome: 'Workflow', acessoId }));
          return data[0];
        })
        .then(workflow => {
          history.push(`/workflows/${workflow.id}`);
        });
    }
  }, [history, acessoId]);

  return (
    <AuthenticatedLayout
      modulo="workflow"
      menu={
        <>
          <AcessoMenu defaultRoute="/workflows" modulo="workflow"/>
          <WorkflowMenu showCreate={isClienteGestor}/>
        </>
      }
    />);
}
