import AuthenticatedLayout from "../../layouts/authenticated";
import { Breadcrumb, Col, Row, Spin, Tree } from "antd";
import FlexCard from "../../components/flex-card";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import useFormatter from "../../hooks/useFormatter";

export default function ArmazenamentoEmUsoPage() {
  const [loading, setLoading] = useState(true);
  const [treeData, setTreeData] = useState([]);
  const { formatBytes } = useFormatter();
  const { t } = useTranslation();

  useEffect(() => {
    unwrapAxiosPromise(axios.get('/armazenamento-em-uso'))
      .then(data => {
        setTreeData([data].map(mapTreeNode));
      })
      .finally(() => setLoading(false))
  }, []);

  return (
    <AuthenticatedLayout breadcrumb={[t('Armazenamento em Uso')]}>
      <Breadcrumb>
        <Breadcrumb.Item>{t('Armazenamento em Uso')}</Breadcrumb.Item>
      </Breadcrumb>
      <FlexCard>
        {
          loading
            ? <Spin/>
            : (
              <Tree
                defaultExpandedKeys={["Total"]}
                autoExpandParent
                showIcon
                showLine={{ showLeafIcon: false }}
                titleRender={({ data }) => (
                  <Row gutter={[8, 8]} type="flex">
                    <Col>{data.name}</Col>
                    <Col>
                      <small>({formatBytes(data.size)})</small>
                    </Col>
                  </Row>
                )}
                treeData={treeData}
              />
            )
        }
      </FlexCard>
    </AuthenticatedLayout>);
}

function mapTreeNode(row) {
  return ({
    children: row.children?.map(mapTreeNode),
    data: row,
    key: row.path,
    title: row.name
  })
}
