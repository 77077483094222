import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../../components/mini-table';
import { compareStrings } from '../../../../util/string';
import { compareBooleans } from "../../../../util/boolean";

export default function ConsultivoAreaUsuariosTable({ dataSource, loading, miniTableRef, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('Nome'),
      dataIndex: 'nome',
      key: 'nome',
      sorter: (a, b) => compareStrings(a.nome, b.nome),
    },
    {
      title: t('E-mail'),
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => compareStrings(a.email, b.email),
    },
    {
      title: t('Herdado'),
      dataIndex: 'herdado',
      key: 'herdado',
      width: 100,
      sorter: (a, b) => compareBooleans(a.herdado, b.herdado),
      render: (text, record) => record.herdado ? t('Sim') : t('Não'),
    }
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        getCheckboxProps: record => ({
          disabled: record.herdado
        }),
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

ConsultivoAreaUsuariosTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
