import React, { useEffect } from 'react';
import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import { useSelector } from "react-redux";
import ConsultivoMenu from "../../../components/consultivo-menu";
import { Col, Row } from "antd";
import { fetchConsultas, fetchConsultasPorStatus, filtroSelector, resetar } from "../../../store/consultivoOperacional";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import ConsultasCard from "./_ConsultasCard";
import { fetchConsultivo } from "../../../store/consultivo";
import ConsultasPorStatusCardGroup from "./_ConsultasPorStatusCardGroup";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { isClienteSelector } from "../../../store/autenticacao";
import ConsultivoFiltroForm from "../filtro/_ConsultivoFiltroForm";
import { consultivoFiltroSelector } from "../../../store/consultivoFiltro";

export default function ConsultivoOperacionalPage() {
  const dispatch = useDispatchWithResult();
  const isCliente = useSelector(isClienteSelector);
  const consultivoFiltro = useSelector(consultivoFiltroSelector);
  const filtro = useSelector(filtroSelector);

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
    dispatch(fetchConsultasPorStatus());
  }, []);

  useDebouncedEffect(500, () => {
    dispatch(fetchConsultasPorStatus());
    dispatch(fetchConsultas());
  }, [consultivoFiltro, filtro])

  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/operacional" modulo="consultivo"/>
          <ConsultivoMenu showCreate={isCliente}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      <ConsultivoFiltroForm/>
      <div style={{ padding: '0 24px' }}>
        <Row gutter={[16, 16]}>
          <ConsultasPorStatusCardGroup/>
          <Col span={24}>
            <ConsultasCard/>
          </Col>
        </Row>
      </div>
    </AuthenticatedLayout>);
}
