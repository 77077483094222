import { Select } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmpresaContrato } from '../../constants';

const { Orgao } = EmpresaContrato;

export default function SelectEmpresaContratoOrgao(props) {
  const { t } = useTranslation();

  const createOption = ({ id, label }) => (
    <Select.Option value={id}>
      {t(label)}
    </Select.Option>);

  return (
    <Select {...props} >
      {createOption(Orgao.AssembleiaGeral)}
      {createOption(Orgao.ConselhoDeAdministracao)}
      {createOption(Orgao.Diretoria)}
      {createOption(Orgao.ConselhoFiscal)}
      {createOption(Orgao.Outros)}
    </Select>
  );
}

SelectEmpresaContratoOrgao.propTypes = {
  value: PropTypes.oneOf([
    Orgao.AssembleiaGeral.id,
    Orgao.ConselhoDeAdministracao.id,
    Orgao.Diretoria.id,
    Orgao.ConselhoFiscal.id,
    Orgao.Outros.id]),
  onChange: PropTypes.func,
};
