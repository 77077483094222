import React, { useEffect } from 'react';
import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import { useSelector } from "react-redux";
import ConsultivoMenu from "../../../components/consultivo-menu";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { fetchConsultivo } from "../../../store/consultivo";
import { isClienteSelector } from "../../../store/autenticacao";
import ConsultasPorStatusCardGroup from "./_ConsultasPorStatusCardGroup";
import { Col, Row } from "antd";
import ConsultasPorAreaGraficoCard from "./_ConsultasPorAreaGraficoCard";
import ConsultasPorStatusGraficoCard from "./_ConsultasPorStatusGraficoCard";
import styles from './_styles.less';
import ConsultasTabelaCard from "./_ConsultasTabelaCard";
import ConsultasPorMesGraficoCard from "./_ConsultasPorMesGraficoCard";
import { fetchConsultas, fetchConsultasPorArea, fetchConsultasPorMes, fetchConsultasPorStatus, resetar } from "../../../store/consultivoDashboard";

export default function ConsultivoDashboardPage() {
  const dispatch = useDispatchWithResult();
  const isCliente = useSelector(isClienteSelector);

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
    dispatch(fetchConsultasPorStatus());
    dispatch(fetchConsultasPorArea());
    dispatch(fetchConsultasPorMes());
    dispatch(fetchConsultas());
  }, []);


  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/dashboard" modulo="consultivo"/>
          <ConsultivoMenu showCreate={isCliente}/>
        </>
      }
      contentStyle={{ padding: 0 }}
    >
      <div className={styles['page']}>
        <ConsultasPorStatusCardGroup/>
        <Row gutter={[0, 0]} justify="center">
          <Col span={24} md={18}>
            <ConsultasPorAreaGraficoCard/>
          </Col>
          <Col span={24} md={6}>
            <ConsultasPorStatusGraficoCard/>
          </Col>
        </Row>
        <Row gutter={[0, 0]} justify="center">
          <Col span={24} md={9}>
            <ConsultasTabelaCard/>
          </Col>
          <Col span={24} md={15}>
            <ConsultasPorMesGraficoCard/>
          </Col>
        </Row>
      </div>
    </AuthenticatedLayout>);
}
