import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { isClienteGestorSelector } from "../../store/autenticacao";
import AuthenticatedLayout from "../../layouts/authenticated";
import AcessoMenu from "../../components/acesso-menu";
import Workflow from "./_Workflow";
import useDispatchWithResult from "../../hooks/useDispatchWithResult";
import { fetchWorkflow } from "../../store/workflow";
import { useRouteMatch } from "react-router";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import WorkflowMenu from "../../components/workflow-menu";

export default function WorkflowPage() {
  const isClienteGestor = useSelector(isClienteGestorSelector);
  const dispatch = useDispatchWithResult();
  const match = useRouteMatch();
  const workflowId = match.params.id;

  useEffect(() => {
    unwrapAxiosPromise(dispatch(fetchWorkflow({ workflowId })));
  }, [workflowId]);

  return (
    <AuthenticatedLayout
      modulo="workflow"
      menu={
        <>
          <AcessoMenu defaultRoute="/workflows" modulo="workflow"/>
          <WorkflowMenu showCreate={isClienteGestor}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      <Workflow/>
    </AuthenticatedLayout>);
}
