import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './_styles.less';
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { Checkbox, List, Popover } from "antd";
import unwrapAxiosPromise from "../../util/unwrapAxiosPromise";
import axios from "axios";
import { useSelector } from "react-redux";
import { acessoSelector } from "../../store/acessos";
import { useTranslation } from "react-i18next";
import { openPopupEmpresa } from "../../util/window";
import useFormatter from "../../hooks/useFormatter";
import * as util from "util";
import useModulo from "../../hooks/useModulo";
import ActionButton from "../action-button";

export default function PesquisaMenu({ onUnloadPopup }) {
  const inputRef = useRef(null);
  const { t } = useTranslation();
  const { moduloAtual } = useModulo();
  const acesso = useSelector(acessoSelector);
  const { formatNumber, parseNumber } = useFormatter();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchContents, setSearchContents] = useState(false);
  const [pesquisado, setPesquisado] = useState(false);
  const [resultado, setResultado] = useState([]);

  const pesquisar = (forceSearchContents) => {
    if (loading)
      return;
    if (search.trim().length > 0) {
      setLoading(true);
      if (forceSearchContents)
        setSearchContents(true);
      unwrapAxiosPromise(axios.get('/acessos/' + acesso?.id + '/pesquisar', { params: { search, searchContents: searchContents || forceSearchContents, modulo: moduloAtual } }))
        .then(data => {
          setResultado(data);
          setPesquisado(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setResultado([]);
      setPesquisado(false);
    }
  }

  const handleClick = ev => {
    ev.preventDefault();
    inputRef.current?.focus();
  }

  const handleChange = ev => {
    setSearch(ev.target.value);
    setPesquisado(false);
  }

  const handleKeyDown = ev => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
      pesquisar(ev.ctrlKey);
    }
  }

  const handleLinkClick = (ev, item) => {
    ev.preventDefault();
    openPopupEmpresa(item.empresa, item.tab, { onUnload: onUnloadPopup });
  }

  useLayoutEffect(() => {
    window.addEventListener('keydown', ev => {
      if (ev.ctrlKey && ev.key === 'k') {
        ev.preventDefault();
        inputRef.current?.focus();
        inputRef.current?.select();
      }
    })
  }, []);

  let headerInfo = null;
  if (loading)
    headerInfo = t('pesquisando...');
  else if (!pesquisado)
    headerInfo = t('digite algo para pesquisar');
  else if (resultado.length === 1)
    headerInfo = util.format(t('exibindo %d item'), resultado.length);
  else if (resultado.length > 1)
    headerInfo = util.format(t('exibindo %d itens'), resultado.length);

  const header = (
    <List.Item key="header">
      <List.Item.Meta
        className={styles['list-item-meta']}
        title={t('Pesquisa Avançada')}
      />
      <div className={styles['cabecalho']}>
        <div
          onClick={e => {
            e.preventDefault();
            setSearchContents(conteudo => !conteudo);
          }}
        >
          <Checkbox
            checked={searchContents}
            disabled={loading}
          >
            {t('Pesquisar em conteúdo de arquivo.')}
          </Checkbox>
        </div>
        <ActionButton
          type="primary"
          disabled={loading}
          icon={loading ? <LoadingOutlined/> : <SearchOutlined/>}
          onClick={pesquisar}
        />
      </div>
    </List.Item>
  );

  let content;

  if (pesquisado) {
    content = <List
      className={styles['list']}
      locale={{ emptyText: t('Nenhum resultado encontrado') }}
      itemLayout="vertical"
      size="small"
      dataSource={resultado}
      header={
        <>
          {header}
          {headerInfo &&
            <List.Item key="subHeader">
              {headerInfo}
            </List.Item>
          }
        </>
      }
      renderItem={(item, index) =>
        (
          <List.Item key={index}>
            <List.Item.Meta
              className={styles['list-item-meta']}
              title={item.empresa.apelido || item.empresa.razaoSocial}
              description={(
                <a href="#" onClick={ev => handleLinkClick(ev, item)}>
                  {item.caminho.join(' / ')}
                </a>
              )}
            />
            <div className={styles['conteudo']}>{item.conteudo}</div>
          </List.Item>
        )}
    />
  } else {
    content = <List
      className={styles['list']}
      itemLayout="vertical"
      size="small"
    >
      {header}
    </List>
  }

  return (
    <div className={styles['pesquisa-menu']} onClick={handleClick}>
      <div>
        {loading ? <LoadingOutlined/> : <SearchOutlined/>}
      </div>
      <Popover
        overlayClassName={styles['popover']}
        placement="bottomLeft"
        content={content}
        trigger="click"
        open={search.length > 0 && open}
        onOpenChange={setOpen}
      >
        <div>
          <input
            ref={inputRef}
            autoComplete="off"
            placeholder={t('Pesquisar...')}
            onClick={e => e.stopPropagation()}
            onFocus={() => {
              setOpen(true);
            }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
        </div>
      </Popover>
    </div>
  );
}
