import {Col, Row, Spin} from 'antd';
import {formatNumber} from 'chart.js/helpers';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import FlexCard from '../../../components/flex-card';
import MiniTable from '../../../components/mini-table';
import useDispatchWithResult from '../../../hooks/useDispatchWithResult';
import useFormatter from '../../../hooks/useFormatter';
import useLoading from '../../../hooks/useLoading';
import {acessoSelector} from '../../../store/acessos';
import {fetchNumerosDestacados, numerosDestacadosSelector} from '../../../store/societarioDashboard';
import {fetchEmpresas} from '../../../store/empresas';
import {openPopupEmpresa} from '../../../util/window';
import styles from './_styles.less';

export default function PLsCard() {
  const miniTableRef = useRef();
  const {t} = useTranslation();
  const {formatCurrency, formatDate} = useFormatter();
  const dispatch = useDispatchWithResult();
  const loading = useLoading(fetchNumerosDestacados.typePrefix);
  const numerosDestacados = useSelector(numerosDestacadosSelector);
  const acesso = useSelector(acessoSelector);

  const onUnload = () => {
    dispatch(fetchEmpresas({acessoId: acesso?.id, modulo: 'societario'}));
  };

  const handleRowClick = record => {
    openPopupEmpresa(record, 'cadastro', {onUnload});
  };

  const columns = [
    {
      title: t('Empresa'),
      key: 'empresa',
      render: (text, record) => record.apelido || record.razaoSocial,
    },
    {
      title: t('Data Base'),
      key: ['pl', 'dataReferencia'],
      width: 120,
      render: (text, record) => formatDate(record.pl.dataReferencia),
    },
    {
      title: t('Patrimônio Líquido (R$)'),
      key: ['pl', 'valorReal'],
      width: 200,
      render: (text, record) => formatCurrency(record.pl.valorReal),
    },
    {
      title: t('Quotas/Ações'),
      key: ['cadastro', 'quotas'],
      width: 200,
      render: (text, record) => formatNumber(record.cadastro.quotas),
    },
    {
      title: t('Capital Social (R$)'),
      key: ['cadastro', 'capitalSocialSubscrito'],
      width: 200,
      render: (text, record) => formatCurrency(record.cadastro.capitalSocialSubscrito),
    },
  ];

  const title = () => {
    return (
        <Row gutter={[8, 8]} align="bottom" justify="end">
          <Col flex="1">
            <h1>{t('Números em destaque')}</h1>
          </Col>
          <Col>
            <MiniTable.DownloadButton disabled={loading || numerosDestacados.length === 0} miniTableRef={miniTableRef} />
          </Col>
        </Row>);
  };

  return (
      <Spin spinning={loading}>
        <FlexCard bordered={false}>
          <div className={styles['numeros-destacados']}>
            <MiniTable
                ref={miniTableRef}
                columns={columns}
                dataSource={numerosDestacados}
                pagination={false}
                rowClassName={styles['numeros-destacados-row']}
                onRow={(record, rowIndex) => ({onClick: () => handleRowClick(record)})}
                title={title}
            />
          </div>
        </FlexCard>
      </Spin>
  );
}
