import { ClearOutlined, DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Dropdown, Menu } from 'antd';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useDispatchWithResult from '../../hooks/useDispatchWithResult';
import { acessoSelector, acessosSelector, fetchAcessos, setAcesso } from '../../store/acessos';
import { isGestorSelector } from '../../store/autenticacao';
import unwrapAxiosPromise from '../../util/unwrapAxiosPromise';
import ActionLink from '../action-link';
import AcessoDrawer from './_AcessoDrawer';
import styles from './_styles.less';
import useCollator from "../../hooks/useCollator";

export default function AcessoMenu({ allowClear, defaultRoute, fetchEmpresas, modulo }) {
  const { t } = useTranslation();
  const { compare, contains } = useCollator();
  const dispatch = useDispatchWithResult();
  const isGestor = useSelector(isGestorSelector);
  const history = useHistory();
  // DROPDOWN
  const acessos = useSelector(acessosSelector);
  const acesso = useSelector(acessoSelector);
  // DRAWER
  const [drawerLoading, setDrawerLoading] = useState(false);
  const [drawerAcesso, setDrawerAcesso] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);

  // FILTRO

  const [filtro, setFiltro] = useState('');
  const acessosFiltrados = useMemo(
    () => acessos
      .filter(acesso => contains(acesso.nome, filtro))
      .sort((a, b) => compare(a.nome, b.nome)),
    [filtro, acessos]);

  const handleSelecionarAcessoClick = e => {
    e.preventDefault();
    setTimeout(() => document.getElementById('acesso-menu-search').focus(), 100);
  }

  // DROPDOWN

  const handleOpenChange = open => {
    if (open) {
      setFiltro('');
      setTimeout(() => document.getElementById('acesso-menu-search').focus(), 100);
    }
  }

  const handleSearchKeyDown = e => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      if (acessosFiltrados.length > 0) {
        e.preventDefault();
        handleSelect(acessosFiltrados[0]);
      }
    }
  }

  const handleSelect = acesso => {
    dispatch(setAcesso({ acesso, fetchEmpresas, modulo }));
    if (defaultRoute)
      history.push(defaultRoute);
  };

  const handleCreateClick = () => {
    setDrawerAcesso({});
    setDrawerVisible(true);
  };

  const handleClearClick = () => {
    dispatch(setAcesso({ acesso: null, fetchEmpresas, modulo }));
    if (defaultRoute)
      history.push(defaultRoute);
  }

  const handleEditClick = acesso => {
    fetchDrawer(acesso.id);
    setDrawerVisible(true);
  };

  const handleRemoveClick = acesso => {
    unwrapAxiosPromise(axios.delete('/acessos', { params: { ids: [acesso.id] } }))
      .then(() => {
        dispatch(fetchAcessos({ fetchEmpresas, modulo }));
      });
  };

  // DRAWER

  const fetchDrawer = (id) => {
    setDrawerLoading(true);
    unwrapAxiosPromise(axios.get(`/acessos/${id}`))
      .then(data => {
        setDrawerAcesso(data);
      })
      .finally(() => setDrawerLoading(false));
  };

  const handleDrawerChange = acesso => {
    dispatch(setAcesso({ acesso, fetchEmpresas, modulo }));
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };

  const handleDrawerAfterClose = () => {
    setDrawerAcesso(undefined);
  };

  //

  return (
    <Dropdown
      onOpenChange={handleOpenChange}
      overlayClassName={styles['overlay']}
      trigger="click"
      overlay={
        <Menu theme="dark" mode="horizontal" selectable={false} disabledOverflow>
          <div className={styles['search']}>
            <SearchOutlined/>
            <input
              autoComplete="off"
              autoFocus
              id="acesso-menu-search"
              placeholder={t('Pesquisar...')}
              onKeyDown={handleSearchKeyDown}
              value={filtro} onChange={e => setFiltro(e.target.value)}
            />
          </div>
          {allowClear && (
            <Menu.Item className={styles['acesso-menu-header']} key="clear" onClick={handleClearClick}>
              <ActionLink
                title={t('Limpar Seleção')}
                icon={<ClearOutlined/>}
              />
            </Menu.Item>
          )}
          <Menu.Divider key="create-divider"/>
          {isGestor && (
            <Menu.Item className={styles['acesso-menu-header']} key="create" onClick={handleCreateClick}>
              <ActionLink
                title={t('Criar Acesso')}
                icon={<PlusOutlined/>}
              />
            </Menu.Item>
          )}
          {acessosFiltrados.map(acesso => {
            const buttons = [];
            if (isGestor) {
              buttons.push(<ActionLink.Edit key="edit" noTitle onClick={() => handleEditClick(acesso)}/>);
              buttons.push(<ActionLink.Remove key="remove" noTitle onClick={() => handleRemoveClick(acesso)}/>);
            }

            return (
              <Menu.Item key={acesso.id} className={styles['acesso-menu-item']} onClick={() => handleSelect(acesso)}>
                <div className={styles['acesso-menu-item-div']}>
                  <span>{acesso.nome}</span>
                  {buttons}
                </div>
              </Menu.Item>
            );
          })}
          <AcessoDrawer
            loading={drawerLoading}
            onAfterClose={handleDrawerAfterClose}
            onChange={handleDrawerChange}
            onClose={handleDrawerClose}
            value={drawerAcesso}
            visible={drawerVisible}
          />
        </Menu>
      }>
      <div className={styles['acesso-menu']} onClick={handleSelecionarAcessoClick}>
        <DownOutlined size={11}/>
        <span>{acesso?.nome ?? t('Selecionar Acesso')}</span>
      </div>
    </Dropdown>
  );
}
