import React, { useEffect } from 'react';
import AuthenticatedLayout from "../../../layouts/authenticated";
import AcessoMenu from "../../../components/acesso-menu";
import { useSelector } from "react-redux";
import { isClienteSelector } from "../../../store/autenticacao";
import ConsultivoMenu from "../../../components/consultivo-menu";
import useDispatchWithResult from "../../../hooks/useDispatchWithResult";
import { fetchConsultivo } from "../../../store/consultivo";
import { useDebouncedEffect } from "../../../hooks/useDebouncedEffect";
import { fetchConsultasPorStatus, resetar } from "../../../store/consultivoCartoes";
import ConsultasPorStatusCollection from "./_ConsultasPorStatusCollection";
import ConsultivoFiltroForm from "../filtro/_ConsultivoFiltroForm";
import { consultivoFiltroSelector } from "../../../store/consultivoFiltro";

export default function ConsultivoCartoesPage() {
  const dispatch = useDispatchWithResult();
  const isCliente = useSelector(isClienteSelector);
  const consultivoFiltro = useSelector(consultivoFiltroSelector);

  useEffect(() => {
    dispatch(fetchConsultivo());
    dispatch(resetar());
  }, []);

  useDebouncedEffect(500, () => {
    dispatch(fetchConsultasPorStatus());
  }, [consultivoFiltro])

  return (
    <AuthenticatedLayout
      modulo="consultivo"
      menu={
        <>
          <AcessoMenu allowClear defaultRoute="/consultivo/cartoes" modulo="consultivo"/>
          <ConsultivoMenu showCreate={isCliente}/>
        </>
      }
      contentStyle={{ maxWidth: 'unset', padding: 0 }}
    >
      <ConsultivoFiltroForm/>
      <ConsultasPorStatusCollection/>
    </AuthenticatedLayout>);
}
