import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Alerta } from '../constants';

const initialState = {
  empresas: [],
  alertasByEmpresaId: {},
  filtro: {
    sucessos: false,
    avisos: false,
    erros: false,
    inativas: false,
    tabs: [],
  },
  empresasFiltradas: [],
};

export const empresasSelector = state => state.empresas.empresas;
export const alertasByEmpresaIdSelector = state => state.empresas.alertasByEmpresaId;
export const filtroSelector = state => state.empresas.filtro;
export const filtradoSelector = createSelector(filtroSelector,
    filtro => !!filtro.sucessos || !!filtro.avisos || !!filtro.erros || !!filtro.inativas || filtro.tabs.length > 0);
export const empresasFiltradasSelector = createSelector(empresasSelector, alertasByEmpresaIdSelector, filtroSelector, filtradoSelector,
    (empresas, alertasByEmpresaId, filtro, filtrado) =>
        empresas.filter(empresa => {
          if (empresa.ativa === filtro.inativas)
            return false;
          if (!filtrado)
            return true;
          const alertas = alertasByEmpresaId[empresa.id] ?? [];
          for (const alerta of alertas) {
            if (filtro.tabs.length > 0) {
              if (!filtro.tabs.includes(alerta.nome)) {
                continue;
              }
            }
            if (filtro.sucessos || filtro.avisos || filtro.erros) {
              switch (alerta.nivel) {
                default:
                  if (!filtro.sucessos)
                    continue;
                  break;
                case Alerta.Nivel.NA.id:
                  continue;
                case Alerta.Nivel.Aviso.id:
                  if (!filtro.avisos)
                    continue;
                  break;
                case Alerta.Nivel.Erro.id:
                  if (!filtro.erros)
                    continue;
                  break;
              }
            }
            return true;
          }
          return false;
        }));

export const fetchEmpresas = createAsyncThunk(
    'empresas/fetchEmpresas',
    async ({acessoId, modulo}, thunkAPI) => {
      try {
        const empresas = (await axios.get('/empresas', {params: {acesso: acessoId}})).data;
        thunkAPI.dispatch(fetchAlertas({ empresas, modulo }));
        return empresas;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchAlertasByEmpresa = createAsyncThunk(
    'empresas/fetchAlertasByEmpresa',
  async ({ id, modulo }, thunkAPI) => {
      try {
        return (await axios.get(`/empresas/${id}/verificar-alertas`, { params: { modulo } })).data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const fetchAlertas = createAsyncThunk(
    'empresas/fetchAlertas',
  async ({ empresas, modulo }, thunkAPI) => {
      try {
        return await Promise.all(empresas.map(empresa =>
          axios.get(`/empresas/${empresa.id}/verificar-alertas`, { params: { modulo } })
                .then(res => ({id: empresa.id, abas: res.data})),
        ));
      } catch (e) {
        return thunkAPI.rejectWithValue(e);
      }
    },
);

export const slice = createSlice({
  name: 'empresas',
  initialState,
  reducers: {
    filtrarEmpresas(state, action) {
      state.filtro.sucessos = action.payload.sucessos ?? state.filtro.sucessos;
      state.filtro.avisos = action.payload.avisos ?? state.filtro.avisos;
      state.filtro.erros = action.payload.erros ?? state.filtro.erros;
      state.filtro.inativas = action.payload.inativas ?? state.filtro.inativas;
      state.filtro.tabs = action.payload.tabs ?? state.filtro.tabs;
    },
  },
  extraReducers: {
    [fetchEmpresas.fulfilled]: (state, action) => {
      state.empresas = action.payload;
    },
    [fetchAlertasByEmpresa.fulfilled]: (state, action) => {
      state.alertasByEmpresaId[action.meta.arg.id] = action.payload;
    },
    [fetchAlertas.fulfilled]: (state, action) => {
      state.alertasByEmpresaId = action.payload.reduce((pv, cv) => {
        pv[cv.id] = cv.abas;
        return pv;
      }, {});
    },
  },
});

export default slice.reducer;

export const {filtrarEmpresas} = slice.actions;
