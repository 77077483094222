import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MiniTable from '../../../components/mini-table';
import useFormatter from '../../../hooks/useFormatter';
import { compareStrings } from '../../../util/string';
import TableCellEllipsis from "../../../components/table-cell-ellipsis";
import { compareNumbers } from "../../../util/number";
import { EmpresaContrato, findByIdAndMap } from "../../../constants";

const { Orgao } = EmpresaContrato;

export default function EmpresaContratosTable({ dataSource, loading, miniTableRef, onClick, onSelectedIdsChange, selectedIds, title }) {
  const { t } = useTranslation();
  const { formatDate } = useFormatter();

  const fireClick = empresa => typeof onClick === 'function' ? onClick(empresa) : undefined;

  const columns = [
    {
      title: t('Nome do Ato'),
      dataIndex: 'nomeAto',
      key: 'nomeAto',
      sorter: (a, b) => compareStrings(a.nomeAto, b.nomeAto),
      render: (text, record) => (
        <Button type="link" onClick={() => fireClick(record)}>
          {text ?? t('(não preenchido)')}
        </Button>
      ),
    },
    {
      title: t('Data do Ato'),
      dataIndex: 'dataAto',
      key: 'dataAto',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataAto, b.dataAto),
      render: (text, record) => formatDate(record.dataAto),
    },
    {
      title: t('Data de Reg.'),
      dataIndex: 'dataRegistro',
      key: 'dataRegistro',
      width: 120,
      sorter: (a, b) => compareStrings(a.dataRegistro, b.dataRegistro),
      render: (text, record) => formatDate(record.dataRegistro),
    },
    {
      title: t('Órgão'),
      dataIndex: 'orgao',
      key: 'orgao',
      width: 210,
      sorter: (a, b) => compareNumbers(a.orgao, b.orgao),
      render: (text, record) => findByIdAndMap(Orgao, record.orgao, orgao => t(orgao.label)),
    },
    {
      title: t('Observação'),
      dataIndex: 'obs',
      key: 'obs',
      sorter: (a, b) => compareStrings(a.obs, b.obs),
      render: (text) => <TableCellEllipsis text={text}/>
    }
  ];

  return (
    <MiniTable
      ref={miniTableRef}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      title={title}
      rowSelection={{
        selectedRowKeys: selectedIds,
        onChange: onSelectedIdsChange,
      }}
    />
  );
}

EmpresaContratosTable.propTypes = {
  dataSource: PropTypes.array,
  loading: PropTypes.bool,
  miniTableRef: PropTypes.object,
  onClick: PropTypes.func,
  onSelectedIdsChange: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  title: PropTypes.func,
};
